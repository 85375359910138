import { withKeys as _withKeys, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55a74a87"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder", "value"]
const _hoisted_2 = { class: "autocomplete-overlay-black" }
const _hoisted_3 = { class: "autocomplete-overlay-grey" }
const _hoisted_4 = {
  class: "text-left autocomplete-results",
  style: {}
}
const _hoisted_5 = ["onMousedown"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["typeahead", $props.outerClass])
  }, [
    _createElementVNode("input", {
      type: "search",
      class: _normalizeClass(["typeahead-input", $props.inputClass]),
      placeholder: $options.placeHolder,
      "aria-autocomplete": "list",
      "aria-label": "Type medication name here to search for a prescription discount coupon",
      autocomplete: "off",
      spellcheck: "false",
      value: _ctx.userQuery,
      onInput: _cache[0] || (_cache[0] = ($event: any) => ($options.updateUserQuery($event))),
      onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.keyup && _ctx.keyup(...args))),
      onKeydown: [
        _cache[2] || (_cache[2] = _withKeys(($event: any) => ($options.hit(-1)), ["enter"])),
        _cache[3] || (_cache[3] = _withKeys(
//@ts-ignore
(...args) => ($options.reset && $options.reset(...args)), ["esc"]))
      ],
      onBlur: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.reset && $options.reset(...args))),
      ref: "typeaheadinput"
    }, null, 42, _hoisted_1),
    _createElementVNode("div", {
      class: "autocomplete-overlay",
      onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.focusInput && $options.focusInput(...args)))
    }, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.userQuery), 1),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.greyQuery.substring(_ctx.userQuery.length)), 1)
    ]),
    _withDirectives(_createElementVNode("ul", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results, (item, index) => {
        return (_openBlock(), _createElementBlock("li", {
          key: index,
          class: "form-inform",
          onMousedown: ($event: any) => {
            $options.hit(index);
            }
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: "d-inline-block",
            icon: "fa fa-search",
            size: "1x",
            "aria-hidden": "true"
          }),
          _createTextVNode("  " + _toDisplayString(item), 1)
        ], 40, _hoisted_5))
      }), 128))
    ], 512), [
      [_vShow, $options.hasResults && !$options.isEmpty]
    ])
  ], 2))
}