<template>
    <div class="">
        <!-- A UI for chat which is decoupled from Hubs -->
        <chat-ui v-if="ChatIrxInputType.String == inputType" :messages="messages" @sendChat="sendChat"></chat-ui>
        <div v-if="ChatIrxInputType.AcceptTOSAndPrivacyPolicy == inputType" class="container-fluid">
            <div class="row">
                <div class="col col-12 d-flex align-items-center justify-content-center w-100 pt-5">
                    <button class="btn btn-info mr-3" @click="navigateToTerms" tabindex="0">ChatIrx Terms of Use</button>
                    <button class="btn btn-info" @click="navigateToPrivacy" tabindex="0">ChatIrx Privacy Policy</button>
                </div>
            </div>
            <div class="row">
                <div class="col col-12">
                    <div class="d-flex flex-column align-items-center justify-content-center w-100 p-5">
                        <button @click="acceptTOS" class="btn btn-lg irx-btn-primary">Accept Terms of Use and Privacy Policy</button>
                        <hr class="w-100"/>
                        <router-link class="" to="/chatirx">No Thanks</router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="container-fluid chatcontrolsarearow" v-if="user === ChatIrxUserType.IrxStaff">
            <div class="row d-flex justify-content-around">
                <div class="col col-6 col-md-3">
                    <button class="btn btn-lg irx-btn-primary" @click="enableChatIrx">Enable ChatIrx</button>
                </div>
                <div class="col col-6 col-md-3">
                    <button class="btn btn-lg irx-btn-primary" @click="disableChatIrx">Disable ChatIrx</button>
                </div>
                <div class="col col-6 col-md-3">
                    <button class="btn btn-lg irx-btn-primary" @click="stopChat">Stop this chat</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { HubConnection } from '@microsoft/signalr';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { sanitize } from 'dompurify';
import { useRoute } from 'vue-router';
import ChatUi from './chat-ui.vue';
import { cookiemixin } from '@/mixins/cookiemixin';

export enum ChatIrxUserType {
    Patient = "patient",
    IrxStaff = "irxstaff",
    ChatIrx = "chatirx",
    FactBot = "factbot",
    System = "system",
}

export enum ChatIrxInputType {
    String = "",
    AcceptTOSAndPrivacyPolicy = "AcceptTOSAndPrivacyPolicy",
}

export interface ChatMessageOnClient {
    sender: string
    time: string
    content: string[]
    isStreaming: boolean
    // for streaming responses, this is set to True when finished receiving all of the message
    isComplete: boolean
}

export interface MessageRequest {
    message: string | null
    user: string
}

interface MessageRequestToGroup {
    message: string | null
    user: string
    groupName: string
}

interface LoadChatHistoryRequest {
    groupName: string
}

interface EnableChatIrxRequest {
    groupName: string
}

interface DisableChatIrxRequest {
    groupName: string
}

interface StopChatRequest {
    groupName: string
}

interface IsChatIrxEnabledRequest {
    groupName: string
}

// 15 * 100 / 1_000 = number of seconds we will wait for login to complete and an auth token to be available before chatting anonymously
// TODO instead of doing this, listen to the keycloak event somehow or set a value in the common store with the auth token & attach that to messages
// and if you login then show a toast, 'you have logged in', or something like that?
const MAX_AUTH_ATTEMPTS = 2;

export default {
    name: 'chatirx-chat',
    components: {
        ChatUi,
    },
    mixins: [
        cookiemixin,
    ],
    data() {
        let d: {
            user: string
            messages: ChatMessageOnClient[]
            messageBeingSent: string
            onConnectSendMessage: boolean
            connection: HubConnection | null
            authAttempts: number
            // if groupName is set then this is a staff chat
            groupName: string | null
            inputType: string
        } = {
            user: ChatIrxUserType.Patient,
            messages: [],
            onConnectSendMessage: false,
            messageBeingSent: "",
            connection: null,
            authAttempts: 0,
            groupName: null,
            inputType: ChatIrxInputType.String,
        }
        return d;
    },
    computed: {
        isPatientChat() {
            return this.groupName == null || this.groupName === '';
        },
        isStaffChat() {
            return !this.isPatientChat;
        },
        ChatIrxUserType: () => ChatIrxUserType,
        ChatIrxInputType: () => ChatIrxInputType,
    },
    methods: {
        acceptTOS() {
            this.setCookie("ChatIrxAcceptedTOSAndPP", true);
            this.inputType = ChatIrxInputType.String;
        },
        getTime() {
            let d = new Date();
            let minNum = d.getMinutes();
            let min = String(minNum);
            if (minNum < 10)
                min = '0' + String(min);
            if (d.getHours() >= 12) {
                return d.getHours() - 12 + ":" + min + "PM";
            }
            return d.getHours() + ":" + min + "AM";
        },
        sendMessageError(err: any) {
            console.error('SendMessage error');
            this.messages.push({
                sender: ChatIrxUserType.System,
                time: this.getTime(),
                content: ['System Error: Chat was disconnected. Trying to re-send your message now.'],
                isComplete: true
            });
            this.onConnectSendMessage = true;
            this.setupSignalR();
            return console.error(err.toString());
        },
        loadChatHistoryError(err: any) {
            console.error('loadChatHistoryError error');
            this.messages.push({
                sender: ChatIrxUserType.System,
                time: this.getTime(),
                content: ['System Error: Could not load chat history'],
                isComplete: true
            });
            this.onConnectSendMessage = true;
            this.setupSignalR();
            return console.error(err.toString());
        },
        adminError(err: any) {
            console.error('adminError');
            this.messages.push({
                sender: ChatIrxUserType.System,
                time: this.getTime(),
                content: ['System Error: Admin error - ' + String(err.toString())],
                isComplete: true
            });
            return console.error(err.toString());
        },
        chatIsInitializingError(err: any) {
            console.error('chatIsInitializingError');
            console.error(err);
            this.onConnectSendMessage = true;
        },
        loadChatHistory() {
            if (this.connection == null) {
                this.chatIsInitializingError();
                return;
            }

            if (this.$keycloak == null || !this.$keycloak.ready) {
                setTimeout(this.loadChatHistory, 200);
                return;
            }

            let loadChatHistoryRequest: LoadChatHistoryRequest = {
                groupName: this.groupName,
            }
            try {
                this.connection.invoke("LoadChatHistory", loadChatHistoryRequest).catch(this.loadChatHistoryError);
            }
            catch (err) {
                console.error('catch this.connection.invoke error');
                console.error(err);
                this.messages.push({
                    sender: ChatIrxUserType.System,
                    time: this.getTime(),
                    content: ['System Error'],
                    isComplete: true
                });
            }
        },
        sendChatToServer(message: string) {
            this.messageBeingSent = message;

            // Do NOT do this - it is possible to connect without Keycloak being ready
            // And it is possible to send a chat without being authenticated
            // if (this.$keycloak == null || !this.$keycloak.ready) {
            //     this.chatIsInitializingError();
            //     return;
            // }

            if (this.connection == null) {
                this.chatIsInitializingError();
                return;
            }

            if (this.isPatientChat) {
                let sendMessageRequest: MessageRequest = {
                    message: message,
                    user: this.user,
                }

                try {
                    // SendMessage updates everyone else with our message
                    this.connection.invoke("SendMessage", sendMessageRequest).catch(this.sendMessageError);
                }
                catch (err) {
                    console.error('catch this.connection.invoke error');
                    console.error(err);
                    this.messages.push({
                        sender: ChatIrxUserType.System,
                        time: this.getTime(),
                        content: ['System Error'],
                        isComplete: true
                    })
                }
                return;
            }

            // Is a staff chat. It is unclear who is sending a chat here.
            let sendMessageRequestToGroup: MessageRequestToGroup = {
                message: message,
                user: this.user,
                groupName: this.groupName,
            }

            if (this.$keycloak == null || !this.$keycloak.ready) {
                setTimeout(this.sendChatToServer, 200, message);
                return;
            }

            try {
                // SendMessage updates everyone else with our message
                this.connection.invoke("SendMessageToGroup", sendMessageRequestToGroup).catch(this.sendMessageError);
            }
            catch (err) {
                console.error('catch this.connection.invoke error');
                console.error(err);
                this.messages.push({
                    sender: ChatIrxUserType.System,
                    time: this.getTime(),
                    content: ['System Error'],
                    isComplete: true
                })
            }
        },
        sendChat(patientChatInput: string) {
            this.sendChatToServer(patientChatInput);
        },
        onConnectionStart() {
            if (this.onConnectSendMessage != null && this.onConnectSendMessage) {
                this.onConnectSendMessage = false;
                this.sendChatToServer(this.messageBeingSent);
            }

            // loadChatHistory if we are joining in the middle of a conversation, which is typical for staff joining into conversations by patients
            if (this.isStaffChat)
                this.loadChatHistory();
        },
        onConnectionStartError(err: string) {
            // if at first you don't succeed, try, try again
            // setTimeout(this.setupSignalR, 100);
            console.error('connection.start() error');
            return console.error(err.toString());
        },
        setupSignalR() {
            if ((this.$keycloak == null || this.$keycloak?.token == null || this.$keycloak?.token == "") && this.authAttempts < MAX_AUTH_ATTEMPTS) {
                this.authAttempts = this.authAttempts + 1;
                // wait and see if we can get that token
                setTimeout(this.setupSignalR, 200);
                return;
            }

            let chatHubURL = '/api/chatirx/chatHub';
            this.connection = new HubConnectionBuilder().withUrl(chatHubURL, { accessTokenFactory: () => "Bearer " + this.$keycloak?.token }).withAutomaticReconnect().build();

            // Disable the send button until connection is established.
            // document.getElementById("sendButton").disabled = true;

            this.connection.on("ReceiveMessage", this.receiveMessage);
            this.connection.on("ChatIrxMessageIsPending", this.ChatIrxMessageIsPending);
            this.connection.on("Stop", this.stop);
            this.connection.on("NotifyChatIrxIsEnabled", this.notifyChatIrxIsEnabled);
            this.connection.on("NotifyChatIrxIsDisabled", this.notifyChatIrxIsDisabled);

            this.connection.start().then(this.onConnectionStart).catch(this.onConnectionStartError);
        },
        receiveMessage(sendMessageRequest: MessageRequest) {
            // console.log('receiveMessage');
            // console.log(sendMessageRequest);
            // If we needed to do something about the inputs, we would use the dompurify library because it is recommended by OWASP
            // In reality .content are rendered with v-text and are safe
            // I am not 100% sure about sender so am sanitizing it
            //
            // I am checking for empty messages to fix a bug I have seen where the parent passes in a bunch of empty messages
            // I am not sure why the parent would do that, but it's happening
            // I am not going to limit message content elsewhere because of the need to support "isComplete: false" and other cases like "isStreaming: true"
            if (sendMessageRequest.message != null && sendMessageRequest.message !== '') {
                this.messages.push({
                    sender: sanitize(sendMessageRequest.user),
                    time: this.getTime(),
                    content: [sendMessageRequest.message],
                    isComplete: true,
                });
            }
            this.hideChatIrxLoading();
        },
        // Called to notify clients that a chat irx message is pending - it has been sent to the server and we are awaiting a response.
        ChatIrxMessageIsPending() {
            // Set up the 'waiting' for the stream
            this.showChatIrxLoading();
        },
        showChatIrxLoading() {
            this.messages.push({
                sender: ChatIrxUserType.ChatIrx,
                time: this.getTime(),
                content: [''],
                isStreaming: true,
                isComplete: false,
            });
        },
        hideChatIrxLoading() {
            for (let i = this.messages.length - 1; i >= 0; i--) {
                let m = this.messages[i];
                if (!m.isComplete) {
                    this.messages.splice(i, 1);
                    return;
                }
            }
        },
        enableChatIrx() {
            if (this.connection == null) {
                this.chatIsInitializingError();
                return;
            }

            if (this.$keycloak == null || !this.$keycloak.ready) {
                setTimeout(this.enableChatIrx, 200);
                return;
            }

            let EnableChatIrxRequest: EnableChatIrxRequest = {
                groupName: this.groupName,
            }
            try {
                this.connection.invoke("EnableChatIrx", EnableChatIrxRequest).catch(this.adminError);
            }
            catch (err) {
                console.error('catch this.connection.invoke error');
                console.error(err);
                this.adminError(err);
            }
        },
        disableChatIrx() {
            if (this.connection == null) {
                this.chatIsInitializingError();
                return;
            }

            if (this.$keycloak == null || !this.$keycloak.ready) {
                setTimeout(this.disableChatIrx, 200);
                return;
            }

            let DisableChatIrxRequest: DisableChatIrxRequest = {
                groupName: this.groupName,
            }
            try {
                this.connection.invoke("DisableChatIrx", DisableChatIrxRequest).catch(this.adminError);
            }
            catch (err) {
                console.error('catch this.connection.invoke error');
                console.error(err);
                this.adminError(err);
            }
        },
        stopChat() {
            // sent from the client to tell the server to stop
            // can only be sent from staff
            if (this.connection == null) {
                this.chatIsInitializingError();
                return;
            }

            if (this.$keycloak == null || !this.$keycloak.ready) {
                setTimeout(this.disableChatIrx, 200);
                return;
            }

            let stopChatRequest: StopChatRequest = {
                groupName: this.groupName,
            };
            try {
                this.connection.invoke("StopChat", stopChatRequest).catch(this.sendMessageError);
            }
            catch (err) {
                console.error('catch this.connection.stop error');
                console.error(err);
                this.adminError(err);
            }
        },
        stop() {
            if (this.connection == null) {
                this.chatIsInitializingError();
                return;
            }

            if (this.$keycloak == null || !this.$keycloak.ready) {
                setTimeout(this.disableChatIrx, 200);
                return;
            }

            try {
                this.messages.push({
                    sender: ChatIrxUserType.System,
                    time: this.getTime(),
                    content: ['Chat was stopped. No more messages can be sent in this chat. A new chat will be started automatically.'],
                    isStreaming: false,
                    isComplete: true,
                });
                this.connection.stop();
            }
            catch (err) {
                console.error('catch this.connection.stop error');
                console.error(err);
                this.adminError(err);
            }
        },
        notifyChatIrxIsEnabled() {
            this.messages.push({
                sender: ChatIrxUserType.System,
                time: this.getTime(),
                content: ['Chat Irx was enabled by staff.'],
                isStreaming: false,
                isComplete: true,
            });
        },
        notifyChatIrxIsDisabled() {
            console.log('disabled');
            this.messages.push({
                sender: ChatIrxUserType.System,
                time: this.getTime(),
                content: ['Chat Irx was disabled by staff.'],
                isStreaming: false,
                isComplete: true,
            });
        },
        navigateToTerms() {
            this.$router.push('/chatirx/termsofuse');
        },
        navigateToPrivacy() {
            this.$router.push('/chatirx/privacypolicy');
        }
    },
    mounted() {
        const route = useRoute();
        this.groupName = route.params['groupName'];
        if (this.groupName != null && this.groupName !== '') {
            this.user = ChatIrxUserType.IrxStaff;
        }

        let acceptedTOSAndPP = this.readCookie("ChatIrxAcceptedTOSAndPP");
        console.log(acceptedTOSAndPP);
        if (acceptedTOSAndPP) {
            this.inputType = ChatIrxInputType.String;
        } else {
            this.inputType = ChatIrxInputType.AcceptTOSAndPrivacyPolicy;
        }

        let toFocus: HTMLInputElement | null = document.querySelector("[placeholder^=Send]");
        if (toFocus != null)
            toFocus.focus();

        this.setupSignalR();
    },
}
</script>
<style scoped>
.chatcontrolsarearow {
    position: relative;
    background-color: #F8F8F8;
    width: 100%;
    height: calc(20px+20px+38px);
    padding-top: 20px;
    padding-bottom: 20px;
}
</style>
