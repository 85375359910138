<template>
    <div class="chatconversationcontainer">
        <section class="py-4 chatarealistrow container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="" ref="messagesRef">
                        <div v-for="(message, index) in messages" :key="index" class="chatListItem row pb-3">
                            <div v-if="message.sender === ChatIrxUserType.Patient"
                                class="col col-10 col-md-9 offset-md-1 d-flex justify-content-end">
                                <div>
                                    <div class="time"><cite class="float-right">{{ message.sender }}<i>{{ message.time
                                    }}</i></cite></div>
                                    <div class="chat-content float-right"
                                        style="white-space: pre-wrap; word-break: keep-all;"
                                        v-for="(c, i) in message.content" :key="i">
                                        <span v-text="c"></span>
                                    </div>
                                </div>
                            </div>
                            <div v-if="message.sender === ChatIrxUserType.Patient" class="col col-2">
                                <div class="chat-img d-flex justify-content-start align-items-center h-100 w-100">
                                    <img src="../../../assets/chatirx/face_for_patient_messages.svg" alt="Patient message icon">
                                </div>
                            </div>

                            
                            <div v-if="message.sender === ChatIrxUserType.ChatIrx && isGreetingMessage(message) && !haveSeenGreetingMessage" class="col col-2">
                                <div class="chat-img d-flex justify-content-end align-items-center h-100 w-100">
                                    <img src="../../../assets/chatirx/robot_2_for_chatirx_messages.svg" alt="Robot icon for ChatIRx messages">
                                </div>
                            </div>
                            <chat-content v-if="message.sender === ChatIrxUserType.ChatIrx && isGreetingMessage(message) && !haveSeenGreetingMessage"
                                :message="message"></chat-content>

                            <div v-if="message.sender === ChatIrxUserType.ChatIrx && !isGreetingMessage(message)" class="col col-2">
                                <div class="chat-img d-flex justify-content-end align-items-center h-100 w-100">
                                    <img src="../../../assets/chatirx/robot_2_for_chatirx_messages.svg" alt="Robot icon for ChatIRx messages">
                                </div>
                            </div>
                            <chat-content v-if="message.sender === ChatIrxUserType.ChatIrx && !isGreetingMessage(message)"
                                :message="message"></chat-content>

                            <div v-if="message.sender === ChatIrxUserType.IrxStaff" class="col col-2">
                                <div class="chat-img d-flex justify-content-end align-items-center h-100 w-100">
                                    <img src="../../../assets/chatirx/badge_for_employee_messages.svg" alt="Employee Messages icon">
                                </div>
                            </div>
                            <chat-content v-if="message.sender === ChatIrxUserType.IrxStaff"
                                :message="message"></chat-content>

                            <div v-if="message.sender === ChatIrxUserType.System" class="col col-2">
                                <div class="chat-img d-flex justify-content-end align-items-center h-100 w-100">
                                    <img src="../../../assets/chatirx/warning_symbol_for_system_messages.svg" alt="Warning icon for system messages">
                                </div>
                            </div>
                            <chat-content v-if="message.sender === ChatIrxUserType.System"
                                :message="message"></chat-content>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div class="sendchatarearow">
            <div class="col col-12 col-md-10 offset-md-1">
                <textarea-auto-resize @enterPressed="sendChat" :maxlen="3072"></textarea-auto-resize>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import ChatContent from "../chat-content.vue";
import TextareaAutoResize from '../../controls/textarea-auto-resize.vue';
import { ChatMessageOnClient, ChatIrxUserType } from './chat.vue';

// A UI for chat which is decoupled from hubs
export default {
    name: 'chatirx-chat',
    components: {
        ChatContent,
        TextareaAutoResize,
    },
    props: {
        messages: { type: Array<ChatMessageOnClient>, default: [] },
    },
    computed: {
        ChatIrxUserType: () => ChatIrxUserType,
        haveSeenGreetingMessage() {
            for (let i = 1; i < this.messages.length; i++) {
                if (this.isGreetingMessage(this.messages[i])) {
                    return true;
                }
            }
            return false;
        },
    },
    emits: ['sendChat'],
    methods: {
        sendChat: function (input: string) {
            this.$emit("sendChat", input);
        },
        isGreetingMessage(message: ChatMessageOnClient) {
            if (message.content.length > 0 && message.content[0].startsWith('I am Chatirx, a pharmacy billing and benefits Question and Answer service. I try to only answer questions for which I know the answer. Do NOT ask me any medical questions which could affect your health - I am not a medical professional.')) {
                return true;
            }
            return false;
        }
    },
}
</script>
<style scoped>
.chatconversationcontainer {
    position: relative;
    height: calc(100vh - 72px) !important;
    /* svh better on mobile; putting second means you fall back to vh */
    height: calc(100svh - 72px) !important;
}

.chatarealistrow {
    position: absolute;
    top: 0;
    width: 100%;
    overflow-y: auto;
    height: calc(100vh - 72px - 70px) !important;
    /* svh better on mobile; putting second means you fall back to vh */
    height: calc(100svh - 72px - 70px) !important;
}

.sendchatarearow {
    position: absolute;
    min-height: 70px !important;
    width: 100%;
    /* min-height: 48px; */
    bottom: 0px;
    right: 0px;
    background-color: #F8F8F8;
}

.chatcontrolsarearow {
    position: relative;
    background-color: #F8F8F8;
    width: 100%;
    height: calc(20px+20px+38px);
    padding-top: 20px;
    padding-bottom: 20px;
}

.chatListItem {
    overflow-y: auto;
    overflow-x: hidden;
}

.chat-img {
    left: 3px;
}

.chat-content,
.chat-img {
    display: inline-block;
    vertical-align: top;
}

.chat-img img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
}

.time {
    width: 100%;
}

cite {
    left: auto;
    right: 60px;
    text-align: right;
    font-style: normal;
    line-height: 24px;
    font-size: 12px;
    white-space: nowrap;
    color: #999;
    text-align: left;
}

cite i {
    font-style: normal;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
}

.chat-content {
    margin-left: 0;
    text-align: left;
    background-color: #33DF83;
    color: hsl(0, 0%, 100%);
    position: relative;
    line-height: 22px;
    /*margin-top: 25px;*/
    padding: 10px 15px;
    background-color: #eee;
    border-radius: 3px;
    color: #333;
    word-break: break-all;
    max-width: 462px \9;
}
</style>
