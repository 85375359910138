<template>
    <div class="chatwelcomecontainer">
        <div class="row h-100">
            <div class="col-12 h-100 justify-content-center align-items-center d-flex">
                <div class="text-center">
                    <h1 class="">ChatIrx</h1>
                    <p>Helping patients reduce the cost of their prescriptions</p>
                    <div>
                        <button class="btn irx-btn-primary btn-lg" @click="chatAnonymously" tabindex="0">Chat Anonymously</button>
                    </div>
                    <div class="pt-5">
                        <button class="btn btn-info mr-3 mt-2" @click="navigateToAbout" tabindex="0">About</button>
                        <button class="btn btn-info mr-3 mt-2" @click="navigateToTerms" tabindex="0">ChatIrx Terms of Use</button>
                        <button class="btn btn-info mt-2" @click="navigateToPrivacy" tabindex="0">ChatIrx Privacy Policy</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
export default {
    name: 'chat-welcome',
    data() {
        return {
            chatLoginUrl: '/'
        }
    },
    methods: {
        logIn() {
            if (this.$keycloak && this.$keycloak.ready && !this.$keycloak.authenticated)
                this.$router.push('/chatirx/login')
            else
                this.$router.push('/chatirx/chat');
        },
        chatAnonymously() {
            if (this.$keycloak && this.$keycloak.ready && this.$keycloak.authenticated)
            {
                this.$router.push('/chatirx/logout');
                return;
            }
            this.$router.push('/chatirx/chat');
        },
        navigateToAbout() {
            this.$router.push('/chatirx/about');
        },
        navigateToTerms() {
            this.$router.push('/chatirx/termsofuse');
        },
        navigateToPrivacy() {
            this.$router.push('/chatirx/privacypolicy');
        }
    },
    mounted() {
    }
}
</script>
<style scoped>
.chatwelcomecontainer {
    margin-top: -72px;
    height: 100vh !important;
}
</style>