import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "input-group my-3" }
const _hoisted_2 = ["rows", "maxlength"]
const _hoisted_3 = { class: "input-group-append" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("textarea", {
      rows: _ctx.numTextareaRows,
      style: {"resize":"none","overflow":"hidden"},
      class: "form-control",
      placeholder: "Send a message",
      "aria-label": "chat input box",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputText) = $event)),
      onKeydown: [
        _cache[1] || (_cache[1] = _withKeys(_withModifiers(() => {}, ["exact","prevent"]), ["enter"])),
        _cache[2] || (_cache[2] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => ($options.onSearch && $options.onSearch(...args)), ["exact"]), ["enter"])),
        _cache[3] || (_cache[3] = _withKeys(_withModifiers((e) => {_ctx.inputText += '\n';$options.doResize(e);}, ["shift","exact","prevent"]), ["enter"]))
      ],
      onInput: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => ($options.doResize && $options.doResize(...args))),
      tabindex: "0",
      ref: "r",
      maxlength: $props.maxlen
    }, null, 40, _hoisted_2), [
      [_vModelText, _ctx.inputText]
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("button", {
        class: "btn irx-btn-primary",
        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($options.onSearch && $options.onSearch(...args))),
        tabindex: "0"
      }, [
        _createTextVNode("Send "),
        _createVNode(_component_font_awesome_icon, {
          class: "px-2",
          icon: "fa fa-paper-plane",
          "aria-hidden": "true",
          width: "24px",
          height: "24px"
        })
      ])
    ])
  ]))
}