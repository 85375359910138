<template>
    <div class="col col-10 col-md-8 d-flex justify-content-start">
        <div>
            <div class="time"><cite class="float-left">{{ message.sender }}<i>{{ message.time
            }}</i></cite></div>
            <div class="chat-content float-left" style="white-space: pre-wrap; word-break: keep-all;">
                <span v-for="(c, i) in message.content" :key="i" v-text="c"></span>
                <div v-if="(message.isStreaming && (!message.isComplete))">
                    <dot-pulse></dot-pulse>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import { PropType } from 'vue'
import { ChatMessageOnClient } from './controls/chat.vue'
import DotPulse from './controls/dot-pulse.vue'
export default {
    name: 'chatirx-chat-content',
    props: {
        message: Object as PropType<ChatMessageOnClient>,
    },
    components: {
        DotPulse
    }
}
</script>
<style scoped>
.time {
    width: 100%;
}

.chat-content {
    margin-left: 0;
    text-align: left;
    background-color: #33DF83;
    color: hsl(0, 0%, 100%);
    position: relative;
    line-height: 22px;
    /*margin-top: 25px;*/
    padding: 10px 15px;
    background-color: #eee;
    border-radius: 3px;
    color: #333;
    word-break: break-all;
    max-width: 462px \9;
}

cite {
    left: auto;
    right: 60px;
    text-align: right;
    font-style: normal;
    line-height: 24px;
    font-size: 12px;
    white-space: nowrap;
    color: #999;
    text-align: left;
}

cite i {
    font-style: normal;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
}
</style>