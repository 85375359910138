<template>
    <nav v-if="(noSearchInHeader && !pinHeader) || showSearchBar || !noSearchInHeader || (pinHeader && showMobileSearchBarNoNavInfo)"
        class="
      navbar
      fixed-top
      navbar-expand-lg navbar-light
      navbar-overall
    " :class="getHeaderClass">
        <router-link class="navbar-brand" to="/">
            <img v-if="darkBackground && !pinHeader && ((noSearchInHeader && !pinHeader) || showSearchBar || !noSearchInHeader)"
                class="img-fluid" src="../../assets/logo-updated.svg" alt="IntelligentRx Logo" />
            <img v-else-if="((noSearchInHeader && !pinHeader) || showSearchBar || !noSearchInHeader)" class="img-fluid"
                src="../../assets/logo-updated-blue.svg" alt="IntelligentRx Logo" />
        </router-link>
        <button class="navbar-toggler ml-auto" :class="getToggleNavColorClass" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <form class="form-inline my-2 my-lg-0 form-for-search-bar">
            <search-bar v-if="(pinHeader && showMobileSearchBarNoNavInfo) || showSearchBar" :inNav="true"
                :doNavigate="this.searchBarDoNavigate"></search-bar>
        </form>

        <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
            <ul class="navbar-nav">
                <!-- <li class="dropdown" @mouseleave="dropdownLeave">
                    <a class="nav-link dropdown-toggle" :class="getColorClass" :active-class="getActiveClass"
                        id="navbarDropdownMenuLink" role="button" aria-haspopup="true" aria-expanded="false"
                        @click="dropdownClick" @mouseover="dropdownClick" href="javascript:void(0)">
                        Employer Program
                    </a>
                    <div class="dropdown-menu" :class="getBackgroundClass" aria-labelledby="navbarDropdownMenuLink"
                        id="navbarDropdownMenu">
                        <router-link class="dropdown-item-custom" :class="getColorClass" :active-class="getActiveClass"
                            to="/for-employers">Savings for Employers</router-link>
                        <router-link class="dropdown-item-custom" :class="getColorClass" :active-class="getActiveClass"
                            to="/for-members">Savings for Members</router-link>
                        <router-link class="dropdown-item-custom" :class="getColorClass" :active-class="getActiveClass"
                            to="/for-benefit-consultants">Benefit Consultants</router-link>
                        <router-link class="dropdown-item-custom" :class="getColorClass" :active-class="getActiveClass"
                            to="/for-health-plans">Health Plans</router-link>
                    </div>
                </li> -->
                <!-- <li class="nav-item">
                    <router-link class="nav-link" :class="getColorClass" :active-class="getActiveClass"
                        to="/for-payers">For Payers</router-link>
                </li> -->
                <!-- <li class="nav-item">
                    <router-link class="nav-link" :class="getColorClass" :active-class="getActiveClass" to="/chatirx">ChatIrx</router-link>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link" :class="getColorClass" :active-class="getActiveClass"
                        to="/blog">Blog</router-link>
                </li>
            </ul>
        </div>
    </nav>
    <div v-else></div>
</template>


<script lang="ts">
import SearchBar from "./../shared/search-bar.vue";
import { mixinDetectingMobile } from "../../mixins/mixinDetectingMobile";
export default {
    name: "header-section",
    mixins: [mixinDetectingMobile],
    components: {
        SearchBar,
    },
    props: {
        pinHeader: { type: Boolean, default: true },
    },
    computed: {
        noSearchInHeader() {
            return this.$route.path === "/" || this.$route.path === "/how-it-works" || this.$route.path === '/p';
        },
        darkBackground() {
            return (
                this.$route.path === "/how-it-works" ||
                this.$route.path === "/" ||
                this.$route.path === "" ||
                this.$route.path === "/mobile-app" ||
                this.$route.path === '/p'
            );
        },
        searchBarDoNavigate() {
            return true;
        },
        getColorClass() {
            if (this.darkBackground) {
                return { "dark-background-link-color": true };
            } else {
                return { "not-dark-background-link-color": true };
            }
        },
        getActiveClass() {
            return this.darkBackground
                ? "dark-background-active"
                : "none-dark-background-active";
        },
        getHeaderClass() {
            if (this.pinHeader) {
                return {
                    "not-dark-background-header pinHeader": true,
                    "p-2": this.pinHeader && this.showMobileSearchBarNoNavInfo,
                    "background-inherit": true,
                };
            } else if (this.darkBackground) {
                return { "background-dark": true };
            } else {
                return {
                    "background-inherit": true,
                };
            }
        },
        getBackgroundClass() {
            if (this.darkBackground) {
                return { "background-dark": true };
            }
            return { "bg-light": true };
        },
        getToggleNavColorClass() {
            if (this.darkBackground && !this.pinHeader)
                return { "toggle-icon-dark-background-color": true };
            return { "toggle-icon-light-background-color": true };
        },
    },
    methods: {
        clickEventListener(e) {
            var navDropdownMenu = document.getElementById('navbarDropdownMenu');
            if (!navDropdownMenu.contains(e.target) && navDropdownMenu.classList.contains('show')) {
                navDropdownMenu.classList.remove('show');
                document.removeEventListener('click', this.clickEventListener);
            }
        },
        dropdownClick(event) {
            document.addEventListener('click', this.clickEventListener);
            document.getElementById('navbarDropdownMenu').classList.add('show');
            event.preventDefault();
            event.stopPropagation();
        },
        dropdownLeave(event) {
            // use top+5 to deal with bug where you can very slowly mouse down & get outside the div which shows the menu AND outside the button which shows the menu
            // And then the emenu hides when it should not hide
            // This did not work because clientY and divRect.top don't match up at all: https://stackoverflow.com/a/36767265/12713117
            if (event.clientY < 60 && event.clientY > 50) {
                // don't allow hovering outside if it's near the top of the page
                return;
            }
            // confirmed outside the target areas
            document.removeEventListener('click', this.clickEventListener);
            document.getElementById('navbarDropdownMenu').classList.remove('show');
        },
        resize() {
            if (window.innerWidth > 1200 && this.pinHeader) {
                // bootstrap xl breakpoint size
                // we want to show the search bar on every page when there is enough room.
                this.showSearchBar = true;
                this.showMobileSearchBarNoNavInfo = false;
            } else if (this.noSearchInHeader && this.pinHeader && this.isMobile()) {
                // The dark-background page is unique in that it shows the header twice.
                // if this.pinHeader && this.noSearchInHeader() then the header is the
                // floating one which appears / disappears based on how far the user has scrolled.
                // That header does not need navigation information.
                this.showMobileSearchBarNoNavInfo = true;
                this.showSearchBar = false;
            } else if (this.noSearchInHeader && this.pinHeader) {
                // The dark-background page is unique in that it shows the header twice.
                // The floating header should not appear on the dark-background page if there is insufficient space to show it.
                this.showSearchBar = false;
                this.showMobileSearchBarNoNavInfo = false;
            } else if (this.noSearchInHeader) {
                // The dark-background page shows the header twice.
                // The non-floating header should never show the search bar.
                this.showMobileSearchBarNoNavInfo = false;
                this.showSearchBar = false;
            } else if (this.isMobile()) {
                // There is insufficient space to show a search bar on mobile.
                this.showMobileSearchBarNoNavInfo = false;
                this.showSearchBar = false;
            } else {
                this.showMobileSearchBarNoNavInfo = false;
                this.showSearchBar = false;
            }
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
    watch: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        $route(to, from) {
            this.resize();
        }
    },
    data() {
        return {
            /* TODO switch this to a single variable with 6 cases: noSearchInHeader, !noSearchInHeader @ mobile, small screen, big screen */
            showSearchBar: false,
            showMobileSearchBarNoNavInfo: false,
        };
    },
    created() {
        window.addEventListener("resize", this.resize);
        this.resize();
    },
    unmounted() {
        window.removeEventListener("resize", this.resize);
    },
};
</script>

<style scoped>
.pinHeader {
    transition: top 0.3s;
    position: fixed;
    top: 0px;
    width: 100%;
    /* z-index: 100; */
}

.not-dark-background-header {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 3px #00000029;
    opacity: 1;
}

.dark-background-active {
    background: var(--background-color-dark-emphasis) 0% 0% no-repeat padding-box;
}

.none-dark-background-active {
    background: #52bce82e 0% 0% no-repeat padding-box;
}

a {
    padding: 8px 12px 8px 12px;
    font: 17px/21px var(--body-font-family);
    opacity: 1;
    text-decoration: none !important;
    text-align: center;
    border-radius: 20px;
    letter-spacing: 1.05px;
}

.dark-background-link-color {
    color: #ffffff !important;
}

.not-dark-background-link-color {
    color: #000000 !important;
}

.navbar-overall {
    min-height: 72px;
    /* Override bootstrap default padding */
    padding-top: 0px;
    padding-bottom: 0px;
}

.background-dark {
    background-color: var(--background-color-dark);
}

.background-inherit {
    background-color: inherit !important;
}

.form-for-search-bar {
    padding-left: 20px;
}

.dropdown-item-custom {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

@media (min-width: 1200px) {
    .form-for-search-bar {
        padding-left: 150px;
    }
}

@media (max-width: 992px) {
    .header-height {
        height: 80px;
    }

    .form-for-search-bar {
        padding-left: 20px;
    }

    .navbar-overall {
        min-height: 72px;
        height: inherit;
        /* Override bootstrap default padding */
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .toggle-icon-dark-background-color {
        background-color: invert(var(--primary-color));
        filter: invert(100%);
    }

    .toggle-icon-light-background-color {
        color: #000000;
    }
}
</style>