<template>
    <chat></chat>
</template>
<script lang="ts">
import Chat from "./controls/chat.vue";

export default {
    name: 'chatirx-chat',
    components: {
        Chat,
    },
    data() {
        let d: {
         } = {
        }
        return d;
    },
    methods: {
    },
    mounted() {
    },
}
</script>
<style scoped>

</style>
