import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3c02d98c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col col-10 col-md-8 d-flex justify-content-start" }
const _hoisted_2 = { class: "time" }
const _hoisted_3 = { class: "float-left" }
const _hoisted_4 = {
  class: "chat-content float-left",
  style: {"white-space":"pre-wrap","word-break":"keep-all"}
}
const _hoisted_5 = ["textContent"]
const _hoisted_6 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_dot_pulse = _resolveComponent("dot-pulse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("cite", _hoisted_3, [
          _createTextVNode(_toDisplayString($props.message.sender), 1),
          _createElementVNode("i", null, _toDisplayString($props.message.time), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.message.content, (c, i) => {
          return (_openBlock(), _createElementBlock("span", {
            key: i,
            textContent: _toDisplayString(c)
          }, null, 8, _hoisted_5))
        }), 128)),
        (($props.message.isStreaming && (!$props.message.isComplete)))
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createVNode(_component_dot_pulse)
            ]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}