<template>
    <div class="input-group my-3">
        <textarea :rows="numTextareaRows" style="resize:none;overflow:hidden;" class="form-control" placeholder="Send a message" aria-label="chat input box"
            v-model="inputText"
            @keydown.enter.exact.prevent
            @keydown.enter.exact="onSearch"
            @keydown.enter.shift.exact.prevent="(e) => {inputText += '\n';doResize(e);}"
            @input="doResize"
            tabindex="0"
            ref="r"
            :maxlength="maxlen"></textarea>
        <div class="input-group-append">
            <button class="btn irx-btn-primary" v-on:click="onSearch" tabindex="0">Send
                <font-awesome-icon class="px-2" icon="fa fa-paper-plane" aria-hidden="true"
                    width="24px" height="24px"></font-awesome-icon></button>
        </div>
    </div>
</template>
<script lang="ts">
import { Ref } from 'vue';
const NUM_Y_PADDINGS=2; // top and bottom padding
const PX_SIZE_OF_PADDINGS=0.75*8; //.75rem to px with 16px height? Not sure why the 16px height seems to be 1/2ed to 8
const TOTAL_EXTRA_SPACE_TO_IGNORE=NUM_Y_PADDINGS*PX_SIZE_OF_PADDINGS;

export default {
    name: 'textarea-auto-resize',
    props: {
        maxlen: { type: Number, default: null }
    },
    emits: ['enterPressed'],
    data() {
        let d: {
            inputText: string
            numTextareaRows: number
            initialScrollHeight: number
            r: Ref | null
        } = {
            inputText: "",
            numTextareaRows: 1,
            initialScrollHeight: 0,
            r: null
        };
        return d;
    },
    methods: {
        doResize(e) {
            const MAX_HEIGHT_IN_ROWS = 10;
            const MIN_HEIGHT_IN_ROWS = 1;
            let currentScrollHeight = e.target.scrollHeight;
            let newHeight = (currentScrollHeight-TOTAL_EXTRA_SPACE_TO_IGNORE) / this.initialScrollHeight;
            if (newHeight > MAX_HEIGHT_IN_ROWS) {
                newHeight = MAX_HEIGHT_IN_ROWS;
            } else if (newHeight < MIN_HEIGHT_IN_ROWS) {
                newHeight = MIN_HEIGHT_IN_ROWS;
            }
            this.numTextareaRows = Math.floor(newHeight);

            // adjust the height of the chat window which displays messages
            let a = Array.from(document.getElementsByClassName('chatarealistrow') as HTMLCollectionOf<HTMLElement>);
            for (let i=0;i<a.length;i++) {
                a[i].style.setProperty("height", "calc(100vh - 72px - 70px - "+String(this.numTextareaRows*this.initialScrollHeight)+"px)", "important");
            }
        },
        onSearch() {
            if (this.inputText == null || this.inputText === '') {
                return;
            }
            this.$emit("enterPressed", this.inputText);
            this.inputText = '';
            this.numTextareaRows = 1;

            // reset the height of the chat window which displays messages
            let a = Array.from(document.getElementsByClassName('chatarealistrow') as HTMLCollectionOf<HTMLElement>);
            for (let i=0;i<a.length;i++) {
                a[i].style.setProperty("height", "calc(100vh - 72px - 70px)", "important");
                a[i].scrollTo(0, a[i].scrollHeight);
            }
        },
    },
    mounted() {
        this.initialScrollHeight = this.$refs.r.scrollHeight - TOTAL_EXTRA_SPACE_TO_IGNORE;
    }
}
</script>